import styles from './ConcurrentUserMessageBox.module.css'

export const ConcurrentUserMessageBox = ({otherUsers}) => {
    return (
        <div className={styles['message-box']}>
            {
                otherUsers.length > 0 ? otherUsers.map((item, index) => (
                    <p className={styles['message-row']} key={index}>
                        {`${item?.alternativeName} is currently editing this form`}
                    </p>
                )) : <div></div>
            }
        </div>
    )
}