import {ProjectFormField} from '@components/ProjectForm';

import styles from "./ProjectFormFieldBuilder.module.css";

export const ProjectFormFieldBuilder = ({ fields, handleSubmit }) => {

    const handleBlur = () => {
        handleSubmit();
    };

    return (
        <>
            { fields.map((element, idx) => {
                return (
                    <div key={idx} className={styles["row-field"]}>
                        { element.map((field, index) => {
                            return <ProjectFormField key={index} handleOnBlur={handleBlur} field={field} index={index} />
                        })}
                    </div>
                )                
            })}
            
        </>
    )
}

export default ProjectFormFieldBuilder
