import { useSocket, SocketReducer } from '@ais/providers';
import { SOCKET_EVENT_NAMES } from '@ais/constants';
import { useEffect, useContext, useReducer, useMemo } from 'react';
import { PROJECT_FORM_CUSTOM_LOCK, PROJECT_FORM_USAGE_ACTIONS } from '@ais/constants';
import { useProjectFormUsageByProjectFormId } from '@services/projectFormUsage';
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { ProjectFormConcurrencyContext } from '@providers'

export const useUserConcurrentList = () => {
	const { socket: socketConnection, isSocketConnected } = useSocket(); 
	const [formUsages, dispatch] = useReducer(SocketReducer, []);
	const {
		state: projectFormState,
		dispatchReducer: dispatchConcurrencyLockReducer
	} = useContext(ProjectFormConcurrencyContext);
	const { projectFormId } = useParams()
	const { accounts } = useMsal();

	const userId = accounts[0].localAccountId.toUpperCase();

	const {
		data: formUsagesResponse,
		isFetching: isFetchingFormUsage,
		isRefetching: isRefetchingFormUsage 
	} = useProjectFormUsageByProjectFormId(projectFormId, true);

	useEffect(() => {
		if (projectFormState.idleData.idle) {
			onDisconnectRemoveUserListener({ userId: projectFormState.idleData.userId })
			dispatchConcurrencyLockReducer({
				type: PROJECT_FORM_CUSTOM_LOCK.UPDATE_IDLE,
				data: {
					idleData: {
						idle: false,
						userId: null
					}
				}
			})
		}
	}, [projectFormState.idleData]) 
	
	useEffect(() => {
		const projectFormUsages = formUsagesResponse?.projectFormUsages ?? []
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.INITIALIZE, projectFormUsages });	
		dispatchConcurrencyLockReducer({
			type: PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_SESSIONS,
			data: projectFormUsages,
		})
    }, [isFetchingFormUsage, isRefetchingFormUsage])

	const onConnectFormInstanceListener = () => {		
		socketConnection.emit(SOCKET_EVENT_NAMES.EMITS.EXT_FORM_INSTANCE, {
			projectFormId,
			userId: userId,
		});
	};

	const onConnectActiveUser = (data) => {
		if (data.projectFormId == projectFormId) {
			dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.UPDATE, projectFormUsage: data });	
		}
	};


	const onDisconnectNotifyListener = () => {
		socketConnection.emit(SOCKET_EVENT_NAMES.EMITS.EXT_REMOVE_USER, { projectFormId, userId });
	};

	const onDisconnectRemoveUserListener = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.DELETE, userId: data?.userId });

		if(userId === data.userId) onConnectFormInstanceListener()
	};

	const onUserSessionIdUpdated = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.UPDATE_SOCKET_ID, payload: data });
	}

	const onSessionDrop = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.SESSION_DROPPED, data });
		dispatchConcurrencyLockReducer({
			type: PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION,
			data,
		})
	} 

	useEffect(() => {
		window.addEventListener('beforeunload', onDisconnectNotifyListener);
		return () => {			
			window.removeEventListener('beforeunload', onDisconnectNotifyListener);
		};
	}, []);

	useEffect(() => {
		if (isSocketConnected) {
			onConnectFormInstanceListener();

			return () => {
				onDisconnectNotifyListener();
			};
		}	
	}, [userId, isSocketConnected]);

	useEffect(() => {
		if (isSocketConnected) {
			socketConnection.on(SOCKET_EVENT_NAMES.EMITS.UPDATE_SOCKET_ID, onUserSessionIdUpdated);
			socketConnection.on(SOCKET_EVENT_NAMES.EMITS.INITIAL_CONNECTION, onConnectActiveUser);
			socketConnection.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, onDisconnectRemoveUserListener);
			socketConnection.on(SOCKET_EVENT_NAMES.EMITS.SESSION_DROPPED, onSessionDrop);
		}

		return () => {
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.UPDATE_SOCKET_ID, onUserSessionIdUpdated);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.INITIAL_CONNECTION, onConnectActiveUser);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, onDisconnectRemoveUserListener);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.SESSION_DROPPED, onSessionDrop);
		}
	}, [socketConnection, isSocketConnected]);

	const otherUsers = useMemo(() => {
		return formUsages.length > 0 ? formUsages.filter(e => e?.userId !== userId) : [];		
	}, [formUsages])

	return { formUsages, otherUsers, onDisconnectRemoveUserListener };
};

