import styles from './ProjectFormFieldWrapper.module.css'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {CLAPerson} from '@ais/components';
import { useUserInfo } from '@services/forms';

const ProjectFormFieldWrapperToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#ffd7d4',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffd7d4',
        color: '#bc2142',
        fontWeight: '400',
        fontSize: '.6rem',
        maxWidth: 500
    },
});

export const ProjectFormFieldWrapper = ({ children, lockingUser, isLocked, ...props }) => {
    const { data: account, isLoading } = useUserInfo(lockingUser && lockingUser.userId ? lockingUser.userId : undefined);
        
    const renderTitle = () => {
        return (<>
            <div className={styles.field_wrapper_locked_tooltip_title}>
                <span style={{ marginRight: '1rem' }}>                    
                    <CLAPerson
                        styles={{
                            height: '40px',
                            width: '40px'
                        }}
                        account={account}
                        personCardInteraction='none'
                        className={styles.person}
                    />                    
                </span>
                <p style={{ margin: 'auto', padding: '.1rem' }}>{lockingUser?.alternativeName} is currently<br />editing this item</p>
            </div>
        </>)
    }

    const renderLockedToolTip = () => {        
        return (
            <ProjectFormFieldWrapperToolTip
                title={renderTitle(lockingUser)}
                open={lockingUser.userId ? true : false}
                placement="top"
                {...props}
            >
                <div style={{ pointerEvents: 'none' }}>
                    {children}
                </div>
            </ProjectFormFieldWrapperToolTip>)
    }

    return (
        <>
            {lockingUser && lockingUser.userId && isLocked ? <>{renderLockedToolTip()}</> :
                <>{children}</>}
        </>
    )
}

export default ProjectFormFieldWrapper;