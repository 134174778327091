import { useContext } from "react"

import { CLAAccordionPro } from '@ais/components';
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

import FinanceStatementProcessContent from "./FinanceStatementProcessContent.jsx";
import styles from './UOEAuditAreaCollapsibles.module.css';

const UOELabelsConstants = UNDERSTANDING_OF_ENTITY.AUDIT_AREAS.FINANCIAL_STEMENT_PROCESS;

const FinanceStatementProcess = (
    {
        financeStatementData,
        currentInternalControlData,
        latestInternalControlData,
        saveInternalControlData,
        updateConcurrentInternalControl
    }
) => {
    const { state: { openComment } } = useContext(UnderstandingOfEntityContext)
    const isCommentOpen = openComment === UOELabelsConstants.COMMENT_KEY

    return (
        <CLAAccordionPro.Root>
            <CLAAccordionPro
                id='financialStatementProcess'
                customClassName={{ container: 'UOEContainer', accordion: 'UOEAccordion' }}
                disableTransitions={true}
            >
                <CLAAccordionPro.Trigger
                    id='financialStatementProcess'
                    customClassName={{ title: '', subtitle: 'flex100' }}
                    styles={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '&.Mui-expanded': {
                                transform: 'none'
                            }
                        }
                    }}
                >
                    <div className={styles.headerContainer}>
                        <div className={styles.gridContainer}>
                            <div className={styles.rowTitle}>
                                Financial Statement Close and Reporting Process
                            </div>
                        </div>
                    </div>

                </CLAAccordionPro.Trigger>

                <CLAAccordionPro.Panel>
                    <FinanceStatementProcessContent
                        financeStatementData={financeStatementData}
                        currentInternalControlData={currentInternalControlData}
                        latestInternalControlData={latestInternalControlData}
                        isCommentOpen={isCommentOpen}
                        saveInternalControlData={saveInternalControlData}
                        updateConcurrentInternalControl={updateConcurrentInternalControl}
                    />
                </CLAAccordionPro.Panel>
            </CLAAccordionPro>
        </CLAAccordionPro.Root>
    );
}

export default FinanceStatementProcess;