import { useContext } from "react";
import update from 'immutability-helper';

import { Box } from '@mui/material';

import {CLAAccordionPro} from '@ais/components';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import { UnderstandingOfEntityContext } from "@ais/contexts";

import {ConcurrentInternalControlTextbox} from '../../ConcurrentInternalControlTextbox/ConcurrentInternalControlTextbox';

import styles from './UOEAuditAreaCollapsibles.module.css';

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEJournalEntriesConstants = UOEConstants.JOURNAL_ENTRIES;

const JournalEntries = ({
    journalEntryInfoProcessing,
    latestInternalControlData,
    saveInternalControlData,
    updateConcurrentInternalControl
}) => {
    const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

    const { state: { openComment } } = useContext(UnderstandingOfEntityContext)
    const isCommentOpen = openComment === UOEJournalEntriesConstants.COMMENT_KEY


    const handleBlur = (event) => {
        if (journalEntryInfoProcessing == null && !event.target.value) return;
        if (journalEntryInfoProcessing === event.target.value) return;

        const newInternalControlData = update(latestInternalControlData.current, {
            JournalEntryICInfoProcessingAndControlActivity: {
                $set: event.target.value
            }
        });

        saveInternalControlData(newInternalControlData, latestInternalControlData.current)
    };

    const handleConcurrentValueChange = (newValue)=> { 
        const newInternalControlData = update(latestInternalControlData.current, {
            JournalEntryICInfoProcessingAndControlActivity: {
                $set: newValue
            }
        });

        updateConcurrentInternalControl(newInternalControlData)
    }

    return (
        <CLAAccordionPro.Root>
            <CLAAccordionPro
                id={UOEJournalEntriesConstants.ACCORDION_ID}
                customClassName={{ container: 'UOEContainer', accordion: 'UOEAccordion' }}
                disableTransitions={true}
                expandedPanel={isCommentOpen ? UOEJournalEntriesConstants.ACCORDION_ID : undefined}
            >
                <CLAAccordionPro.Trigger
                    id={UOEJournalEntriesConstants.ACCORDION_ID}
                    customClassName={{ title: 'title', subtitle: 'flex100' }}
                    styles={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '&.Mui-expanded': {
                                transform: 'none'
                            }
                        }
                    }}
                >
                    <div className={styles.headerContainer}>
                        <div className={styles.gridContainer}>
                            <div className={styles.rowTitle}>Journal Entries</div>
                        </div>
                    </div>
                </CLAAccordionPro.Trigger>

                <CLAAccordionPro.Panel
                    panelStyles={{
                        padding: 'auto',
                        paddingRight: 0,
                        backgroundColor: '#FFF'
                    }}
                >
                    <Box sx={{ ml: '45px', my: '20px' }}>
                        <ConcurrentInternalControlTextbox
                            value={journalEntryInfoProcessing || ''}
                            onBlur={handleBlur}
                            label={UOEInfoProcessingConstants.LABEL}
                            placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                            customFormObjectId={UOEJournalEntriesConstants.FIELD}
                            onConcurrentValueChange={(newValue) => handleConcurrentValueChange(newValue)}
                        />
                    </Box>
                </CLAAccordionPro.Panel>
            </CLAAccordionPro>
        </CLAAccordionPro.Root>
    );
}

export default JournalEntries;