import React from 'react';

import styles from '../Footer.module.css';

export const FooterNavDisclaimer = () => {
  return (
    <div className={styles.globalFooterDisclaimer}>
      <p className={styles.globalFooterDisclaimerP}>
        CliftonLarsonAllen is a Minnesota LLP, with more than 120 locations across the United States. The Minnesota certificate number is 00963. The California license number is 7083. The Maryland permit number is 39235. The New York permit number is 64508. The North Carolina certificate number is 26858. If you have questions regarding individual license information, please contact <a href="mailto:webmaster@claconnect.com?subject=Web%20Inquiry%3A%20Licensure" className={styles.globalFooterUnderlineA}>Elizabeth Spencer</a>.
      </p>
      <p className={styles.globalFooterDisclaimerP}>
        CLA (CliftonLarsonAllen LLP), an independent legal entity, is a network member of CLA Global, an international organization of independent accounting and advisory firms. Each CLA Global network firm is a member of CLA Global Limited, a UK private company limited by guarantee. CLA Global Limited does not practice accountancy or provide any services to clients. CLA (CliftonLarsonAllen LLP) is not an agent of any other member of CLA Global Limited, cannot obligate any other member firm, and is liable only for its own acts or omissions and not those of any other member firm. Similarly, CLA Global Limited cannot act as an agent of any member firm and cannot obligate any member firm. The names “CLA Global” and/or “CliftonLarsonAllen,” and the associated logo, are used under license.
      </p>
      <p className={styles.globalFooterDisclaimerLastChildP}>
        <a
          href="https://www.bluecrossmn.com/transparency-coverage-machine-readable-files"
          className={styles.globalFooterUnderlineA}
        >
          Transparency in coverage machine-readable files
        </a>
      </p>
    </div>
  );
};
