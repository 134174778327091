import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { SchemaProvider } from "@ais/providers";
import { theme } from '@ais/theme';

/**
 * A function that takes a list of providers and returns
 * one provider that contains all of them
 * 
 * Functionality below will be the same as writing the following:
 * 
 * const AppProviders = ({ children }) => (
 *  <ThemeProvider>
 *      <SchemaProvider>
 *          { children }
 *      </SchemaProvider>
 *  </ThemeProvider>
 * ) 
 */

function ThemeProvider({ children }) {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}


const combineProviders = (providerList, children) =>
    providerList?.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, <>{children}</>)

export const AppProviders = ({ children }) =>
    combineProviders(
        [
            ThemeProvider,
            SchemaProvider,
        ],
        children
    )

