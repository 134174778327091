import React from 'react';
import Container from '@mui/material/Container';

import { HeaderMenu } from './HeaderMenu';
import { HeaderNavigation } from './HeaderNavigation';

export const Header = () => {
  return (
    <>
      <Container sx={{
        display: 'grid !important',
        margin: '0 !important',
        padding: '0 !important',
        minWidth: '650px'
      }} maxWidth={'true'}>
        <HeaderMenu />
        <HeaderNavigation />
      </Container>
    </>
  );
};
