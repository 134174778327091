import React, {useState} from 'react';
import {Box} from '@mui/material';

import {
  FacebookIcon,
  FacebookWhiteIcon,
  InstagramIcon,
  InstagramWhiteIcon,
  LinkedinIcon,
  LinkedinWhiteIcon,
  TwitterIcon,
  TwitterWhiteIcon,
  YoutubeIcon,
  YoutubeWhiteIcon
} from '@assets';
import styles from '../Footer.module.css';

export const FooterSocialItem = ({
  icon,
  link
}) => {
  const [over,setOver] = useState(false);

  const getImageSource = () => {
    switch(icon) {
      case 'Facebook':
        return over ? FacebookIcon : FacebookWhiteIcon;
      case 'Instagram':
        return over ? InstagramIcon : InstagramWhiteIcon;
      case 'LinkedIn': 
        return over ? LinkedinIcon : LinkedinWhiteIcon;
      case 'Twitter': 
        return over ? TwitterIcon : TwitterWhiteIcon;
      case 'YouTube':
        return over ? YoutubeIcon : YoutubeWhiteIcon;
      default:
        return null;
    }
  }

  return (
    <li className={styles.globalFooterSocialListItem}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{cursor: over ? 'pointer' : ''}}
        className={styles.globalListItemA} 
        onMouseOver={() => setOver(true)} 
        onMouseOut={() => setOver(false)}
      >
        <Box component="img" src={getImageSource()} sx={{height: '30px', width: '30px'}}/>
      </a>
    </li>
  );
};
