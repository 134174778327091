import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }


    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    render() {
        if(this.state.errorInfo) {
            return (
                <div>
                    <h2>{ this.props.message || "Something went wrong" }</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        { this.state.error && this.state.error.toString() }
                    </details>
                </div>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary
