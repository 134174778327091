import axios from "axios"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { authenticated, msalInstance, NotLoggedInError } from "./authentication"
import { extFormScopes as scopes } from "./scopes"

let axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_EXT_FORM_BASE,
    timeout: 30000,
    headers: {
        "Accept": "application/json"
    }
});

axiosClient.interceptors.request.use(async (config) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    if (!authenticated()) {
        throw new NotLoggedInError(
            'Unable to make request to CLA API; not logged in.'
        );
    }
    let token;
    try {
        const authResult = await msalInstance.acquireTokenSilent({
            scopes: scopes,
            account: activeAccount || accounts[0]
        });
        token = authResult.accessToken;
    }
    catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            const authResult = await msalInstance.acquireTokenPopup({
                scopes: scopes
            })

            token = authResult.accessToken;
        }
        else {
            throw error;
        }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, null);

export default axiosClient;
