import { useParams } from 'react-router-dom';
import { SOCKET_EVENT_NAMES, PROJECT_FORM_CUSTOM_LOCK } from '@ais/constants';
import { useReducer, useMemo, useState, useEffect, createContext } from 'react';
import { useSocket, ProjectFormInstanceConcurrencyReducer } from '@ais/providers'; 
import { useMsal } from '@azure/msal-react';

const initialState = {
    sessionsData: [],
    userId: null,
    projectFormId: null,
    projectId: null,
    subscribedQuestionIds: [],
    lastSessionUpdate: null,
    idleData: {
        idle: false,
        userId: null
    }
};

export const ProjectFormConcurrencyContext = createContext({});

export const ProjectFormConcurrencyProvider = ({ children }) => {
    const { socket } = useSocket();
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toUpperCase();
    const { projectId, projectFormId } = useParams(); 
    
    const [lastEmmitedEvent, setLastEmmitedEvent] = useState(null);
    const [state, dispatchReducer] = useReducer(ProjectFormInstanceConcurrencyReducer, initialState);

    const emitLastEvent = () => {
        if(!lastEmmitedEvent) return;
        
        const { eventName, data } = lastEmmitedEvent;

        // When last event emitted, don't continue unless acknowledged from backend
        (async () => {
            await new Promise((resolve) => {
                socket.emit(eventName, data, () => {
                    resolve();
                });
            });
        })()
    } 
    useEffect(() => {
        dispatchReducer({
            type: PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_BASE_STATE,
            data: {
                userId, projectId, projectFormId
            }
        })
    }, [userId, projectId, projectFormId])

    // Subscribe to BROADCAST_QUESTION_UPDATE, BROADCAST_REMOVE_USER and UPDATE_SOCKET_ID events
    useEffect(() => {
        if(!socket) return;

        socket.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_QUESTION_UPDATE, (data) => {
            dispatchReducer({
                type: PROJECT_FORM_CUSTOM_LOCK.UPSERT_SESSION,
                data
            })
            
            if (data?.idle) {
                dispatchReducer({
                    type: PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION,
                    data: { projectFormId: data.projectFormId, userId: data.userId, sessionId: data.userSessionId }
                })
                dispatchReducer({
                    type: PROJECT_FORM_CUSTOM_LOCK.UPDATE_IDLE,
                    data: {
                        idleData: {
                            idle: data.idle,
                            userId: data.userId
                        }
                    }
                }) 
            } 
        })

        socket.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, (data) => {
            dispatchReducer({
              type: PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION,
              data
            })
        })
    }, [socket])

    useEffect(() => {
        const onReconnect = () => {
            emitLastEvent()
        };
        socket.on(SOCKET_EVENT_NAMES.RECONNECT, onReconnect)
        return () => {
            socket.off(SOCKET_EVENT_NAMES.RECONNECT, onReconnect)
        }
    }, [lastEmmitedEvent, socket.id])

    const memoized = useMemo(
        () => ({ state, dispatchReducer, setLastEmmitedEvent }),
        [state, dispatchReducer, setLastEmmitedEvent]
    )

    return (
        <ProjectFormConcurrencyContext.Provider value={{
            ...memoized,
        }}>{children}</ProjectFormConcurrencyContext.Provider>
    )
}

