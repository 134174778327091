import React from "react"
import ReactDOM from "react-dom"
import { MsalProvider } from "@azure/msal-react"
import { EventType } from "@azure/msal-browser"
import { QueryClientProvider } from "@tanstack/react-query"

import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { queryClient } from "./routes/AppRoutes"
import App from './App'
import './index.css';
import { msalInstance as msal } from "./utilities/authentication"

msal.enableAccountStorageEvents()
if(!msal.getActiveAccount() && msal.getAllAccounts().length > 0) {
    msal.setActiveAccount(msal.getAllAccounts()[0]);
}
msal.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      msal.setActiveAccount(event.payload.account);
      window.location.reload();
    }
})

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msal}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
