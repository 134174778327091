import { MenuItem, Paper, Typography, MenuList, SvgIcon} from "@mui/material"
import { Link } from "react-router-dom"
import { CLASimpleDrawer } from "@ais/components"
import { colors } from "@ais/theme"
import { useRelatedFieldsByQuestionIds } from "../../../services/forms"
import { useProjectFormContext } from "../../../contexts/ProjectFormContext"

export const ProjectFormLinkPanel = (props) => {

    const { open, setOpen, questionId, disabled = false } = props
    const { projectId } = useProjectFormContext()
    const { data: fields } = useRelatedFieldsByQuestionIds(projectId, questionId)

    return (
        <CLASimpleDrawer
            anchor="right"
            isVisible={open}
            onClose={() => setOpen(false)}
            allowBackDropCloseClick
        >
            <Paper sx={{ p: 4 }}>
                <Typography variant="title" sx={{ fontSize: "24px" }}>
                    Linked Fields
                </Typography>

                <MenuList
                    sx={{ mt: 2, display: "flex", flexDirection: "column", rowGap: 1 }}
                >
                    { fields && fields.map((field, index) => {
                        const questions = field.questionIds.join(',')
                        return (
                            <MenuItem
                                key={index}
                                component={Link}
                                to={`/todos/project/${projectId}/form/${field.projectFormId}?sectionId=${field.sectionId}&questionIds=${questions}`}
                                target="_blank"
                                sx={{
                                    px: 1,
                                    "&.MuiMenuItem-root": {
                                        color: colors.navy[300]
                                    },
                                    "&.Mui-disabled": {
                                        opacity: 1
                                    },
                                }}
                                disabled={disabled}
                            >
                                { `${field.projectFormName} - ${field.sectionName}`}
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Paper>
        </CLASimpleDrawer>
    )
}
