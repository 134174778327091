import React from 'react';

import styles from '../Footer.module.css';
import {FooterSocialItem} from './FooterSocialItem';

export const FooterSocial = () => {
  const socialMediaIcons = [
    {name: 'LinkedIn', link: 'https://www.linkedin.com/company/cliftonlarsonallen'},
    {name: 'Twitter', link: 'https://twitter.com/CLAconnect'},
    {name: 'Facebook', link: 'https://www.facebook.com/CliftonLarsonAllen'},
    {name: 'YouTube', link: 'https://www.youtube.com/user/CliftonLarsonAllen'},
    {name: 'Instagram', link: 'https://www.instagram.com/lifeatcla/'},
  ];

  return (
    <div className={styles.globalFooterSocial}>
      <ul className={styles.globalFooterSocialList}>
        {socialMediaIcons.map((icon, index) => (
          <FooterSocialItem 
            key={index}
            icon={icon.name}
            link={icon.link}
          />
        ))}
      </ul>
    </div>
  );
};
