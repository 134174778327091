import React from 'react';

import styles from '../Footer.module.css';

export const FooterNavFinePrint = () => {
  return (
    <div className={styles.globalFooterFinePrint}>
      <h4 className={styles.globalFooterNavTitle}>
        Call us at <a href="tel:1-888-529-2648" className={styles.globalFooterUnderlineA}>1-888-529-2648</a>.
      </h4>
      <p className={styles.globalFooterFinePrintP}>
        <a href="https://www.claconnect.com/en/general/privacy-policy" className={styles.globalFooterUnderlineA}>Privacy policy</a>
      </p>
      <p className={styles.globalFooterFinePrintP}>
        <a href="https://www.claconnect.com/en/general/website-terms-of-use-and-disclaimers" className={styles.globalFooterUnderlineA}>Terms of use and disclaimers</a>
      </p>
      <p className={styles.globalFooterFinePrintP}>
        <a href="https://www.claconnect.com/en/general/wealth-advisors-disclosures" className={styles.globalFooterUnderlineA}>CliftonLarsonAllen Wealth Advisors, LLC disclaimers</a>
      </p>
      <p className={styles.globalFooterFinePrintP}>
        © 2023 CliftonLarsonAllen. All rights reserved. "CliftonLarsonAllen" and "CLA" refer to CliftonLarsonAllen LLP.
      </p>
      <p className={styles.globalFooterFinePrintP}>
        Investment advisory services are offered through CliftonLarsonAllen Wealth Advisors, LLC, an SEC-registered investment advisor.
      </p>
      <p className={styles.globalFooterFinePrintP}>
        <a href="https://www.claglobal.com/" className={styles.globalFooterUnderlineA}>
          <img 
            src="https://www.claconnect.com/-/media/graphics/logos/cla_global_network_member_logo_mark_1c_wht.png?h=55&w=111&rev=67c31eb0ce334ff793f0d52b265efec4&hash=7BE1CCA1A0D1058078B2B476A7F23018"
            width="111"
            height="55"
            alt="CLA Global Logo"
          />
        </a>
      </p>
    </div>
  );
};
