import api from "../utilities/api"
import { useQuery } from '@tanstack/react-query'

export const getProjectFormUsageByProjectFormId = async (projectFormId) => {
    const { data } = await api.get(`/client-user/projectformusage/${projectFormId}`);
    return data;
};

export const useProjectFormUsageByProjectFormId = (projectFormId, enabled = true) => {
    return useQuery(
        ['useProjectFormUsageByProjectFormId', projectFormId],
        () => getProjectFormUsageByProjectFormId(projectFormId),
        {
            enabled: !!projectFormId && !!enabled,
            refetchOnWindowFocus: false
        }
    );
}

const projectFormusageService = {
    getProjectFormUsageByProjectFormId,    
};

export default projectFormusageService;
