import React from 'react';
import {Stack} from '@mui/material';

import {FooterSocial} from './FooterSocial'
import {FooterNav} from './FooterNav';

export const Footer = () => {
  return (
    <Stack>
      <FooterSocial />
      <FooterNav />
    </Stack>
  )
};
