import { useQuery } from "@tanstack/react-query"
import { json } from "react-router-dom";

import api from "../utilities/api"
import jwt from "jwt-decode"

import { msalInstance } from "../utilities/authentication";

const PROJECT_QUERY_FACTORY = {
    project: (projectId) => ["project", projectId],
    engagementLeader: (projectId) => ["project-engagement-leader", projectId],
};

export const projectAuthorizeQuery = (projectId, projectFormId) => ({
    queryKey: PROJECT_QUERY_FACTORY.project(projectId),
    queryFn: async () => {
        const payload = {projectFormId}
        await api.post(`/client-user/projects/${projectId}/authorize`, payload);
    }
});

export const projectLoader = (queryClient) => async (
    {params: {projectId, projectFormId} }
) => {
    try {
        // FIXME: We should uncomment this block of code
        // once we have the provisioning of external users
        // const activeAccount = msalInstance.getActiveAccount()
        // const authResult = await msalInstance.acquireTokenSilent({
        //     account: activeAccount
        // });
        // const decoded = jwt(authResult.accessToken)
        // if(!decoded.roles) {
        //     throw new json("no_access", 401)
        // }
        const projectAuthorize = projectAuthorizeQuery(projectId, projectFormId);
        await queryClient?.fetchQuery(projectAuthorize);
        return true;
    }
    catch (error) {
        throw error.response ? json({ projectId: projectId, projectFormId: projectFormId }, error.response.status) : error
    }
};

export const useProjectEngagementLeader = (projectId) => {
    return useQuery(
        PROJECT_QUERY_FACTORY.engagementLeader(projectId),
        async () => {
            const { data } = await api.get(`/projects/${projectId}/engagement-leader`)
            return data;
        },
        {
            enabled: !!projectId,
            retry: false
        }
    )
}
