import { FormSection, ProjectFormFieldBuilder } from "@components/ProjectForm";

export const ProjectFormSection = ({ schema, handleSubmit }) => {
    return (
        <>
            {schema?.map((element) => {
                switch (element.type) {
                    case 'SECTION':
                    case 'SEMICUSTOM':
                        return (
                            <FormSection key={element.id} section={element}>
                                <ProjectFormFieldBuilder handleSubmit={handleSubmit} fields={element.fields} />
                            </FormSection>
                        )
                    default: {
                        return null
                    }
                }
            })}

        </>
    )
}

export default ProjectFormSection
