import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {ClaLogo} from "@ais/assets";

import styles from './Header.module.css';

export const HeaderMenu = () => {
  const menuList = [
    { title: 'Who We Serve', to: '/', isMenu: true },
    { title: 'What We Do', to: '/', isMenu: true },
    { title: 'Who We Are', to: '/', isMenu: true },
    { title: 'Insights', to: '/', isMenu: false },
    { title: 'Careers', to: '/', isMenu: false },
  ];  

  const imgComponent = () => {
    return (
        <img src={ClaLogo} sx={{height: '45px', width: '45px'}} alt="CLA Logo" />
    )
  };

  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { md: 'flex' } }}
        >
          {imgComponent()}
        </Typography>
        <Box sx={{
          flexGrow: 1,
          minWidth: '700px',
          display: { md: 'flex' },
          justifyContent: 'end',
          pt: '50px',
          pb: '20px'
        }}>
          {menuList.map((page, index) => (
            <Button
              key={index}
              disableRipple
              disableElevation
              onClick={() => { }}
              endIcon={page.isMenu ? <KeyboardArrowDownIcon /> : null}
              sx={{
                fontFamily: 'museo-sans',
                fontWeight: 400,
                fontSize: '22px',
                color: '#595959'
              }}>
              {page.title}
            </Button>
          ))}
        </Box>

        <Box sx={{ minWidth: '100px' }}>
          <IconButton sx={{ marginBottom: '-30px' }}>
            <SearchIcon sx={{ height: '35px', width: '35px' }} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
};
