import { useContext, useState, useEffect } from 'react';
import { SOCKET_EVENT_NAMES, PROJECT_FORM_CUSTOM_LOCK } from '@ais/constants';
import { useSocket } from '@ais/providers';
import { ProjectFormConcurrencyContext } from '@providers';
import { useMsal } from '@azure/msal-react';
export const jsonSerializer = (data) => {
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
}

export const useProjectFormConcurrentLocking = (questionId, options = {}) => {
    const {
        summaryProcedureId = null,
        projectFormProcedureStepId = null,
        procedureComponentId = null,
        customFormObjectId = null,
    } = options;
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const { socket } = useSocket();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedField, setLockedField] = useState(null)
    const [lockingUser, setLockingUser] = useState(null);
    const [concurrentValue, setConcurrentValue] = useState(null);
    const { state, dispatchReducer, setLastEmmitedEvent } = useContext(ProjectFormConcurrencyContext);
    const [_, setOriginalValue] = useState(null);

    useEffect(() => {
        dispatchReducer({ type: PROJECT_FORM_CUSTOM_LOCK.SUBSCRIBE_QUESTION_ID, data: questionId })
    }, [questionId]);

    // Update isLocked if any of the ongoing session contains the one that used this hook
    useEffect(() => {
        if (!state?.sessionsData) return;

        const lockingSession = state.sessionsData.find(({
            questionId: sessionQuestionId,
            summaryProcedureId: sessionSummaryProcedureId,
            projectFormProcedureStepId: sessionProjectFormProcedureStepId,
            procedureComponentId: sessionProcedureComponentId,
            customFormObjectId: sessionCustomFormObjectId
        }) =>
            questionId === sessionQuestionId &&
            summaryProcedureId == sessionSummaryProcedureId &&
            projectFormProcedureStepId == sessionProjectFormProcedureStepId &&
            procedureComponentId?.toLowerCase() == sessionProcedureComponentId?.toLowerCase() &&
            customFormObjectId == sessionCustomFormObjectId
        );

        const lockingUser = !!lockingSession ? {
            userId: lockingSession.userId,
            alternativeName: lockingSession.alternativeName,
        }
            : null;

        if (!lockingUser || lockingSession?.sessionId === socket.id || lockingSession?.userId.toLowerCase() === userId) {
            setLockingUser(null);
            setIsLocked(false);
        } else {
            setLockingUser(lockingUser)
            setIsLocked(!!lockingUser)
        }
    }, [state?.sessionsData])

    // Update concurrent value if current value is the one that used this hook
    useEffect(() => {
        // should not process if lastSessionUpdate is empty
        if(!state?.lastSessionUpdate) return;
        
        if(state.lastSessionUpdate?.prevQuestionId !== questionId) return;
        const { 
            summaryProcedureId: lastSummaryProcedureId,
            projectFormProcedureStepId: lastProjectFormProcedureStepId,
            procedureComponentId: lastProcedureComponentId,
            customFormObjectId: lastCustomFormObjectId
        } = state.lastSessionUpdate;
        if (
            lastSummaryProcedureId == summaryProcedureId &&
            lastProjectFormProcedureStepId == projectFormProcedureStepId &&
            lastProcedureComponentId == procedureComponentId &&
            lastCustomFormObjectId == customFormObjectId
        ) {
            const serializedAnswer = jsonSerializer(state?.lastSessionUpdate?.answer);
            const answerAsObject =  typeof serializedAnswer === 'number' ? serializedAnswer.toString() : serializedAnswer 
            setConcurrentValue(answerAsObject);
        }
    }, [state?.lastSessionUpdate]); 

    // Start lock for other users
    const emitLockEvent = (initialValue) => {
        setOriginalValue(initialValue)
        const eventName = SOCKET_EVENT_NAMES.EMITS.EXT_QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: questionId,
            projectId: state.projectId,
            prevQuestionId: null,
            summaryProcedureId,
            projectFormProcedureStepId,
            procedureComponentId,
            customFormObjectId,
        }
        socket.emit(eventName, data);
        if (questionId) setLockedField(questionId);
        else if (customFormObjectId) setLockedField(customFormObjectId);
        setLastEmmitedEvent({eventName, data});
    }

    // Ends lock for other users and update their value
    const emitUnlockEvent = (answer, idle = false, allowEmptyString = false) => {
        setOriginalValue(answer)
        const serializedJSON = jsonSerializer(answer)
        const answerAsJSON = typeof serializedJSON === 'string' ? serializedJSON : JSON.stringify(serializedJSON);

        const eventName = SOCKET_EVENT_NAMES.EMITS.EXT_QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: null,
            projectId: state.projectId,
            prevQuestionId: questionId,
            answer: answerAsJSON === '' && allowEmptyString ? '' : answerAsJSON || null,
            summaryProcedureId,
            projectFormProcedureStepId,
            procedureComponentId,
            customFormObjectId,
            idle
        }
        
        socket.emit(eventName, data);
        if (idle) {
            socket.emit(SOCKET_EVENT_NAMES.EMITS.EXT_REMOVE_USER, {projectFormId: state.projectFormId, userID: state.userId})
        }
        setLastEmmitedEvent({eventName, data});
    }

    const emitResetEvent = () => {
        const eventName = SOCKET_EVENT_NAMES.EMITS.EXT_QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            projectId: state.projectId,
            questionId: null,
            prevQuestionId: null,
            answer: null,
            summaryProcedureId: null,
            projectFormProcedureStepId: null,
            procedureComponentId: null,
            customFormObjectId: null,
        }
        socket.emit(eventName, data);
        setLastEmmitedEvent({eventName, data});
    }

    const emitUpdateEvent = (answer, allowEmptyString = false, callback) => {
        setOriginalValue(answer)
        const serializedJSON = jsonSerializer(answer)
        const answerAsJSON = typeof serializedJSON === 'string' ? serializedJSON : JSON.stringify(serializedJSON);

        const eventName = SOCKET_EVENT_NAMES.EMITS.EXT_QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            projectId: state.projectId,
            questionId: questionId,
            prevQuestionId: questionId,
            answer: answerAsJSON === '' && allowEmptyString ? '' : answerAsJSON || null,
            summaryProcedureId,
            projectFormProcedureStepId,
            procedureComponentId,
            customFormObjectId,
        }
        socket.emit(eventName, data);

        callback && callback()
        setLastEmmitedEvent({eventName, data});
    }

    return {
        isLocked,
        lockingUser,
        lockedField,
        emitLockEvent,
        emitUnlockEvent,
        emitResetEvent,
        emitUpdateEvent,
        concurrentValue,
    };
}