import React from "react";
import {ErrorPageBackground} from "@ais/assets";
import {ClaLogoWhite} from "@ais/assets";

import {PROJECT_FORM} from '@constants';

import styles from "./Unauthorized.module.css";

const Unauthorized = ({ projectId }) => {

    const {UNAUTHPAGE_CONTENT_LINE_1, UNAUTHPAGE_CONTENT_LINE_2} = PROJECT_FORM.EN;
    
    return (
        <div className={styles["unauthorized-container"]}>
            <div className={styles["background-image-container"]}>
                <img src={ErrorPageBackground} className={styles["background-image-container__image"]} alt="background svg" />
            </div>

            <div className={styles["content-container"]}>
                <img src={ClaLogoWhite} className={styles["content-container__logo"]} alt="background svg" />

                <div className={styles["content-container__title"]}>Notice</div>

                <hr className={styles["content-container__title-divider"]} />

                <div className={styles["content-container__subtitle"]}>
                    {UNAUTHPAGE_CONTENT_LINE_1}
                    <br />
                    <br />
                    {UNAUTHPAGE_CONTENT_LINE_2}
                </div>
            </div>
        </div>
    );
};

export default Unauthorized;
