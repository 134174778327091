import React from 'react';

import styles from '../Footer.module.css';

export const FooterNavList = ({list, title}) => {
  return (
    <div className={styles.globalFooterNavKnowUsGetStarted}>
      <h3 className={styles.globalFooterNavTitle}>{title}</h3>
      <ul className={styles.globalFooterNavNoBullets}>
        {list.map((item, index) => (
          <li key={index} className={styles.globalFooterNavLi}>
            <a href={item.link} className={styles.globalFooterNavA}>{item.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
