import React from 'react';
import {Container, Box} from '@mui/material';
import {Outlet} from "react-router-dom"

import {Header, Footer} from '@components/Common';

export const AppLayout = () => {
  return (
    <Container 
      sx={{
        height: '100vh', 
        display: 'flex',
        flexDirection: 'column'
      }} 
      maxWidth="100%" 
      disableGutters
    >
      <Header />
      <Container 
        maxWidth="100%" 
        disableGutters
      >
        <Box as="div" sx={{flex: 1, minHeight: '500px'}}>
          <Outlet />
        </Box>
      </Container>
      <Footer />
    </Container>
  );
};
