export const PROJECT_FORM = {
  EN: {
    CONFIRM_SUBMIT_TITLE: 'Confirmation',
    CONFIRM_SUBMIT_CONFIRM_BUTTON: 'Submit',
    CONFIRM_SUBMIT_CANCEL_BUTTON: 'Cancel',
    CONFIRM_SUBMIT_MESSAGE: 'Are you sure you want to submit answers?',
    SUBMIT_ANSWERS: 'Submit Answers',
    FORM_SUBMITTED_HEADER: 'Form has been submitted!',
    FORM_SUBMITTED_VERBIAGE: 'Thank you for submitting the form. Return to ClientHQ if you have remaining To-Dos to complete',
    FORM_SUBMITTED_GO_TO_TODO: 'Go to To-Do List',
    UNAUTHPAGE_CONTENT_LINE_1: 'You do not currently have access to this form.',
    UNAUTHPAGE_CONTENT_LINE_2: 'Please contact a member of your CLA team to request access.'
  }
}