import { useCallback, useContext, useState } from 'react';
import { InternalControlContext } from '@components/CustomForm/InternalControl/UnderstandingOfEntity/context/InternalControlContext';

export const useICF = () => {
    const [currentInternalControlData, setCurrentInternalControlData] = useState(null);

    const {
        internalControlData,
        setInternalControlData,
        concurrencyEventReceived,
        setConcurrencyEventReceived
    } = useContext(InternalControlContext);

    const handleInternalControlDataChanged = useCallback((data) => {
        const { code, ...rest } = data;

        switch (code) {
            case 'whole':
                setCurrentInternalControlData(rest);
                break;
            case 'ICInfoProcessingAndControlActivity':
                const internalControl = currentInternalControlData;
                const { ICInfoProcessingAndControlActivity, ProjectScopeAuditAreaSCOTABDId } = rest
                let projectScopeAuditArea = [];
                internalControl.ProjectScopeAuditArea.forEach((data) => {
                    const { ProjectScopeAuditAreaSCOTABDS: projectScopeAuditAreaSCOTABDS } = data;
                    if (projectScopeAuditAreaSCOTABDS) {
                        for (const psaScotabds of projectScopeAuditAreaSCOTABDS) {
                            if (psaScotabds.ProjectScopeAuditAreaSCOTABDId === parseInt(ProjectScopeAuditAreaSCOTABDId)) {
                                psaScotabds.ICInfoProcessingAndControlActivity = ICInfoProcessingAndControlActivity;
                                break;
                            }
                        }
                    }
                    projectScopeAuditArea.push(data)
                })
                internalControl.ProjectScopeAuditArea = projectScopeAuditArea; 
                setCurrentInternalControlData(internalControl);
                break;
            default:
                break;
        } 
    }, [currentInternalControlData])

    return {
        internalControlData,
        setInternalControlData,
        concurrencyEventReceived,
        setConcurrencyEventReceived,
        handleInternalControlDataChanged,
        currentInternalControlData,
        setCurrentInternalControlData
    }
}

