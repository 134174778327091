import { Box } from '@mui/material';
import update from 'immutability-helper';

import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

import {ConcurrentInternalControlTextbox} from '../../ConcurrentInternalControlTextbox/ConcurrentInternalControlTextbox';
import styles from './UOEAuditAreaCollapsibles.module.css';

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEFinancialStatementConstants = UOEConstants.AUDIT_AREAS.FINANCIAL_STEMENT_PROCESS;

const FinanceStatementProcessContent = (
    {
        financeStatementData,
        latestInternalControlData,
        saveInternalControlData,
        updateConcurrentInternalControl
    }
) => {
    const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

    const handleBlur = (event, key) => {
        if (financeStatementData[key] === event.target.value) return;
        const newInternalControlData = update(latestInternalControlData.current, {
            [key]: {
                $set: event.target.value
            }
        });

        saveInternalControlData(newInternalControlData, latestInternalControlData.current)
    };

    const handleConcurrentValueChange = (item, newValue)=> { 
        const newInternalControlData = update(latestInternalControlData.current, {
            [item]: {
                $set: newValue
            }
        });
        updateConcurrentInternalControl(newInternalControlData)
    }

    return (
        <Box className={styles.contentContainer}>
            {
                Object.keys(UOEFinancialStatementConstants.LABELS).map((item, index) => (
                    <div className={styles.textfieldContainer} key={`financialStatementInfo-${index++}`}>
                        <span className={styles.numberedText}>
                            {UOEFinancialStatementConstants.LABELS[item]}
                        </span>
                        <ConcurrentInternalControlTextbox
                            label={UOEInfoProcessingConstants.LABEL}
                            placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                            onConcurrentValueChange={(newValue) => handleConcurrentValueChange(item, newValue)}
                            value={financeStatementData[item] || ''}
                            maxLength={4096}
                            onBlur={(e) => handleBlur(e, item)}
                            customFormObjectId={`financial-statement-${index}`}
                        />
                    </div>
                ))
            }
        </Box>
    );
}

export default FinanceStatementProcessContent;
