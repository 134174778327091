import React, { useEffect } from "react"
import {
    createRoutesFromElements,
    createBrowserRouter,
    Route,
    RouterProvider,
    Outlet,
} from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { QueryClient } from "@tanstack/react-query"
import loadable from "@loadable/component"
import { AppTypeProvider } from '@ais/providers'

import { ProjectFormContextProvider } from "@contexts";
import { formScopes } from "../utilities/scopes.js";
import { AppLayout } from '@layouts';
import { AppProviders } from "@providers";
import { projectLoader } from '@services';
import NoAccessErrorBoundary from "../components/Common/NoAccessErrorBoundary/NoAccessErrorBoundary";

const Dashboard = loadable(() => import("@views/Dashboard"));
const ProjectFormRenderer = loadable(() => import("@views/ProjectFormRenderer/ProjectFormRenderer"))
const ProjectFormRendererV2 = loadable(() => import("@views/ProjectFormRenderer/ProjectFormRendererV2"))

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
})

export const RedirectClientHQ = () => {
    window.location.replace(process.env.REACT_APP_CLIENTHQ_TODOS_URL);
    return <>Loading ...</>;
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            id="shared_forms"
            errorElement={<NoAccessErrorBoundary />}
            element={<Outlet />}
        >
            <Route path="/dashboard" element={<AppLayout />} >
                <Route index element={<Dashboard />} />
            </Route>
            <Route
                id="todos"
                path="/todos"
                element={<AppLayout />}
                errorElement={<NoAccessErrorBoundary />}
            >
                <Route index element={<RedirectClientHQ />} />
                <Route
                    id="project-form"
                    path="project/:projectId/form/:projectFormId"
                    element={
                        <ProjectFormContextProvider>
                            <ProjectFormRenderer />
                        </ProjectFormContextProvider>
                    }
                    loader={projectLoader(queryClient)}
                />
                <Route
                    id="project-form-v2"
                    path="v2/project/:projectId/form/:projectFormId"
                    element={
                        <ProjectFormContextProvider>
                            <ProjectFormRendererV2 />
                        </ProjectFormContextProvider>
                    }
                    loader={projectLoader(queryClient)}
                />
            </Route>
        </Route>
    )
)

export const AppRoutes = () => {
    const { accounts, instance } = useMsal();

    useEffect(() => {
        const getTokenFromMsalInstance = async () => {
            const request = { scopes: formScopes, account: accounts[0] };
            const authResult = await instance.acquireTokenSilent(request);
            accounts[0].idTokenClaims.accessToken = authResult.accessToken;
        }

        getTokenFromMsalInstance();
    }, [])

    return (
        <AppProviders>
            <AppTypeProvider isPortalApp={false}>
                <RouterProvider router={router} />
            </AppTypeProvider>
        </AppProviders>
    )
}