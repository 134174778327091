import React from 'react';

import styles from '../Footer.module.css';
import {FooterNavDisclaimer} from './FooterNavDisclaimer';
import {FooterNavFinePrint} from './FooterNavFinePrint';
import {FooterNavList} from './FooterNavList';

export const FooterNav = () => {
  const knowUsList = [
    {name: 'Contact Us', link: 'https://www.claconnect.com/en/contact-us'},
    {name: 'Locations', link: 'https://www.claconnect.com/en/locations'},
    {name: 'Directory', link: 'https://www.claconnect.com/en/directory'},
    {name: 'About', link: 'https://www.claconnect.com/en/who-we-are'},
    {name: 'Careers', link: 'https://www.claconnect.com/en/careers'},
    {name: 'CLA Speakers Bureau', link: 'https://www.claconnect.com/general/speakers-bureau'},
    {name: 'Global Affiliation', link: 'https://www.claconnect.com/en/who-we-are/international-reach'},
    {name: 'Logos', link: 'https://www.claconnect.com/en/who-we-are/logo-download'}
  ];

  const getStartedList = [
    {name: 'Client Login', link: 'https://www.claconnect.com/en/general/client-login'},
    {name: 'Pay CLA Bill Online', link: 'https://ww2.payerexpress.com/ebp/CLA/'},
    {name: 'Email Subscriptions', link: 'https://www.claconnect.com/en/forms/subscribe-to-cla-communications'},
    {name: 'Industries', link: 'https://www.claconnect.com/en/industries'},
    {name: 'Services', link: 'https://www.claconnect.com/en/services'},
    {name: 'Resources', link: 'https://www.claconnect.com/en/resources'},
    {name: 'CLA Blogs', link: 'http://blogs.claconnect.com/'},
    {name: 'Webinars', link: 'https://www.claconnect.com/en/events'},
    {name: 'Media', link: 'https://www.claconnect.com/en/media'},
  ];

  return (
    <div className={styles.globalFooterNav}>
      <div className={styles.globalFooterNavContainer}>
        <div className={styles.globalFooterNavWrapper}>
          <FooterNavList title="Get to Know Us" list={knowUsList} />
          <FooterNavList title="Get Started" list={getStartedList} />
          <FooterNavFinePrint />
          <FooterNavDisclaimer />
        </div>
      </div>
    </div>
  );
};
