import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { NavLink, useRouteLoaderData } from 'react-router-dom';

import styles from './Header.module.css';

export const HeaderNavigation = () => {
  const subMenuList = [
    { title: 'Dashboard', to: '/dashboard'},
    { title: 'To-Dos', to: '/todos' },
  ];
  // temporarily disable red dot icon for testing purposes
  const sharedForms = [] // useRouteLoaderData('shared_forms');

  return (
    <AppBar position="static" className={styles.appBar} sx={{ padding: 0 }}>
      <Toolbar disableGutters>
        <Box sx={{
          flexGrow: 1,
          minWidth: '700px',
          display: { md: 'flex' },
          background: '#2d3b59',
          paddingLeft: '65px'
        }}>
          {subMenuList.map((page, index) => (
            <NavLink
              key={index}
              to={page.to}
              end={page.to === '/' ? true : false}
              className={
                ({isActive}) => `${styles.claNavLink} ${isActive ? styles.claNavLinkActive : ""}`
              }
              data-test="nav-link"
            >
              {page.title} 
              {page.to === '/todos' && sharedForms?.length > 0 && <span className={styles.todoNotification}>*</span>}
            </NavLink>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  )
};
