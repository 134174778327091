import api from "../utilities/api";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

const FETCH_PROJECT_INTERNAL_CONTROL = 'fetchProjectInternalControls';

const fetchProjectInternalControls = async (projectFormId, projectId) => {
  const { data } = await api.get(`/client-user/internal-controls/${projectFormId}/${projectId}`);
  return data;
};

// EXPORTS ====================================================================
export const useFetchProjectInternalControls = (projectFormId, projectId) => {
  return useQuery(
    [FETCH_PROJECT_INTERNAL_CONTROL, projectFormId, projectId],
    () => fetchProjectInternalControls(projectFormId, projectId),
    {
      enabled: !!projectFormId && !!projectId,
      onError: (error) => {
        throw error;
      }
    }
  );
};

export const useUpdateInternalControlAuditAreas = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ projectFormId, payload }) => {
      const { data } = await api.post(`/client-user/internal-controls/${projectFormId}/audit-areas`, payload)
      return data
    }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
    }
  });
};

export const useUpdateFirstLevelInternalControlData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ projectFormId, payload }) => { 
      const { data } = await api.post(`/client-user/internal-controls/${projectFormId}`, payload)
      return data
    }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
    }
  });
};

export const useUpdateProjectScopeAuditAreaSCOTABDFields = () => {
  return useMutation(
    async ({ payload, projectFormId }) => {
      const { data } = await api.post(`/client-user/projectscopesauditareascotabds/${projectFormId}`, payload);
      return data
    }, {
    onError: (error) => {
      throw error;
    }
  });
};
