import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const InternalControlContext = createContext({});

export const InternalControlProvider = ({ children }) => {

    const [internalControlData, setInternalControlData] = useState([]); 
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false);
    const memoized = useMemo(
        () => ({
            internalControlData,
            setInternalControlData,
            concurrencyEventReceived,
            setConcurrencyEventReceived
         }),
        [
            internalControlData,
            setInternalControlData,
            concurrencyEventReceived,
            setConcurrencyEventReceived
        ]
    )

    return (
        <InternalControlContext.Provider value={memoized}>{children}</InternalControlContext.Provider>
    )
}

InternalControlProvider.propTypes = {
    children: PropTypes.node
}