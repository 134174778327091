import { useState, useEffect, useRef } from 'react'

import { InternalControlTextbox } from "@ais/components";
import { useUpdateMyPresence, useRoomIdle } from '@providers/concurrency/RoomProvider';
import { useOthers } from "@providers/concurrency/store/users"; 
import ProjectFormFieldWrapper from '@components/Common/ProjectFormFieldWrapper/ProjectFormFieldWrapper';
import { useMsal } from '@azure/msal-react';

export const ConcurrentInternalControlTextbox = ({
  label,
  placeholder,
  maxLength = 4096,
  onBlur,
  onFocus,
  value,
  customFormObjectId,
  onConcurrentValueChange,
  ...props
}) => { 
  const [content, setContent] = useState(value)
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const { accounts } = useMsal()
  const userId = accounts[0].localAccountId.toLowerCase();  
  const focusedField = others.find(
    (user) => user.presence.focusedId === customFormObjectId && user.info.userId != userId
  )
  const isIdle = useRoomIdle();
  const ICInfoRef = useRef(null)

  useEffect(() => {
    setContent(value);
  }, [value]);

  useEffect(() => {
    if (isIdle) {
      setContent(value)
      ICInfoRef.current?.blur()
    }
  }, [isIdle]);

  const handleBlur = (e) => {
    if (isIdle) { 
      ICInfoRef.current = null
      updateMyPresence({ focusedId: null, type: 'custom' })
      return
    }
    if (typeof onBlur === 'function') onBlur(e);
    ICInfoRef.current = null
    updateMyPresence({ focusedId: null, type: 'custom' })
  }

  const handleFocus = (e) => {
    if (typeof onFocus === 'function') onFocus(e, customFormObjectId);
    ICInfoRef.current = e.target;
    updateMyPresence({ focusedId: customFormObjectId, type: 'custom' })
  }

  const handleOnChange = (event) => {
    setContent(event.target.value)
  }
  const lockingUser = focusedField ? { userId: focusedField?.info?.userId, alternativeName: focusedField?.info?.name } : undefined; 
  return (
    <ProjectFormFieldWrapper lockingUser={lockingUser} isLocked={!!focusedField} >
      <InternalControlTextbox
        label={label}
        placeholder={placeholder}
        value={content}
        maxLength={maxLength}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleOnChange}
        disabled={!!focusedField}
        {...props}
      />
    </ProjectFormFieldWrapper>
  )
}

export default ConcurrentInternalControlTextbox;
